















import { Component, Vue } from 'vue-property-decorator'
import LabelBase from '@/components/atoms/LabelBase.vue'

@Component({
  components: {
    LabelBase,
  },
})
export default class TestTeacherHistoryHeader extends Vue {
  get isActive(): any {
    return function (target: string) {
      // @ts-ignore
      const path = this.$route.path
      const active = path.split('/').slice(-1)[0]
      return {
        active: target === active,
      }
    }
  }

  private isV3 = Vue.prototype.$gdlsCookiesV3.isV3()
}























import { Component, Vue, Watch } from 'vue-property-decorator'
import StudentHistorySidebar from '@/components/organisms/StudentHistorySidebar.vue'
import TestTeacherHistoryHeader from '@/components/organisms/TestTeacherHistoryHeader.vue'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import StudentHistoryTotal from '@/components/organisms/StudentHistoryTotal.vue'
import TitleBase from '@/components/atoms/TitleBase.vue'
import TitleTextBase from '@/components/atoms/TitleTextBase.vue'
import SelectBase from '@/components/atoms/SelectBase.vue'
import { FormatResultSummaries } from '@/models/api/resultSummaries'
import TeacherHistoryBottomPanel from '@/components/organisms/TeacherHistoryBottomPanel.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'

@Component({
  components: {
    StudentHistorySidebar,
    TestTeacherHistoryHeader,
    ColoredBox,
    StudentHistoryTotal,
    TitleBase,
    TitleTextBase,
    SelectBase,
    TeacherHistoryBottomPanel,
    ButtonBase,
  },
})
export default class LearningSituation extends Vue {
  private userId = Vue.prototype.$cookies.get('dataGdls').student?.userId || 0

  private totalStatusDatas = {}

  private selectTestOptions: { text: string; value: string }[] = []

  private selectedTest = ''

  private hasTermDate = true

  @Watch('selectedTest')
  onSelectedTestChange(newTestDate: string) {
    if (newTestDate) {
      const [start, end, _] = newTestDate.split(',')
      if (start && end) {
        this.hasTermDate = true
        this.getResultSummaries({ start: start, end: end })
      } else {
        this.hasTermDate = false
      }
    }
  }

  private created(): void {
    if (this.userId === 0) return
    this.getTermExamsTerms()
  }

  private async getTermExamsTerms() {
    Vue.prototype.$http.httpWithToken.get('/termExams/terms', { params: { userId: this.userId } }).then((res: any) => {
      if (res.data.length === 0) {
        this.hasTermDate = false
        return
      }
      this.selectTestOptions = res.data.map(
        (term: { gradeName: string; termName: string; from: string; to: string }, index: number) => {
          return { text: `${term.gradeName} ${term.termName}`, value: `${term.from},${term.to},${index}` }
        }
      )
      const selectedIndex = res.data.findIndex((term: { isSelected: boolean }) => {
        return term.isSelected === true
      })
      const selected = res.data[selectedIndex]
      this.selectedTest = `${selected.from},${selected.to},${selectedIndex}`
    })
  }

  private async getResultSummaries(params: { start: string; end: string }) {
    Vue.prototype.$http.httpWithToken
      .get(`/history/resultSummaries/${this.userId}`, {
        params: { classModeCode: 'TK', startAt: params.start, endAt: params.end },
      })
      .then((res: any) => {
        this.totalStatusDatas = FormatResultSummaries(res.data)
      })
  }
}
